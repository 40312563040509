<template>
  <div>
    <img src="@/assets/bg2.png" class="bg" alt="" />
    <div class="min-width detail">
      <p class="tag">
        当前位置:<router-link to="/home">首页</router-link>
        <router-link :to="'/document/' + getTag.id" v-if="getTag.name"
          >>{{ getTag.name }}</router-link
        >
        <span>>详情</span>
      </p>
      <p class="title">{{ data.title }}</p>
      <p class="label">发布日期: {{ $dateFormat(data.updatetime) }}</p>
      <video-player
        class="video-player"
        v-if="data.abb_file"
        :options="{
          autoplay: false,
          controls: true,
          src: data.abb_file,
          
        }"
       :style-video="{ height: '50vw' }"
      />
      <p class="content" v-html="data.content"></p>
    </div>
  </div>
</template>

<script>
import { getArticleDetail, getStyleDetail } from "@/api";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "detail",
  components: {
    VideoPlayer,
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    getTag() {
      const data = this.$store.state.navAndTag.find(
        (n) => n.id == this.data.category_id
      );
      return data || {};
    },
  },
  async created() {
    const { id } = this.$route.params;
    const { type } = this.$route.query;
    const { data } = await getArticleDetail({ id, type });
    this.data = data;
  },
  methods: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.detail {
  min-height: 540px;
  .tag {
    margin-top: 10px;
    * {
      color: #818181;
    }
  }
  .title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
  }
  .label {
    font-size: 13px;
    text-align: center;
    margin: 10px 0 20px;
  }
  .video-player {
    margin-bottom: 20px;
  }
  .content {
    position: relative;
  }
}
</style>
<style lang="less" >
// .content {
//   img {
//     position: relative;
//     left: 50%;
//     transform: translateX(-50%);
//   }
// }
</style>
