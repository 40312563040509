import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Select,Option, Table, TableColumn, DatePicker, Pagination } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
import VueAwesomeSwiper from'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import 'swiper/dist/css/swiper.css'

import Video from 'video.js'
import 'video.js/dist/video-js.css'


// 全局方法
import $f from '@/unit/$f'
Vue.use($f)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)

Vue.use(DatePicker)
Vue.use(Pagination)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
