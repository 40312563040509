import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/home/index'
import home from '../views/home/home'
import document from '../views/home/document'
import detail from '../views/home/detail'

Vue.use(VueRouter)
const meta = {
  keepAlive: true
}
const routes = [{
  path: '/',
  name: 'Index',
  meta,
  component: index,
  redirect: '/home',
  children: [{
    path: '/home',
    name: 'Home',
    meta,
    component: home,
  },
  {
    path: '/document/:id',
    name: 'Document',
    meta,
    component: document,
  }, {
    path: '/detail/:id',
    name: 'Detail',
    meta,
    component: detail,
  },

  ]
},
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: "/dist/",
  routes
})


export default router