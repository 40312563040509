import myAxios from "./axios"

// 登录
export const userLogin = (data) => myAxios({ url: 'api/user/login', method: 'post', data })

// 获取滚播数据
export const getBanner = (params) => myAxios({ url: 'api/fwpt/getBanner', params })

// 获取分类与标签(渲染导航用)
export const getNavAndTag = (params) => myAxios({ url: 'api/fwpt/getNavAndTag', params })

// 获取文章(合并)
export const getArticle = (params) => myAxios({ url: 'api/fwpt/getArticle', params })

// 获取文章列表
export const getArticleList = (params) => myAxios({ url: 'api/fwpt/getArticleList', params })

// 获取文章详情
export const getArticleDetail = (params) => myAxios({ url: 'api/fwpt/getArticleDetail', params })

// 获取文章详情
export const getStyleDetail = (params) => myAxios({ url: 'api/fwpt/getStyleDetail', params })

