<template>
  <div>
    <img src="@/assets/bg2.png" class="bg" alt="" />
    <div class="min-width document">
      <div class="search">
        <input type="text" v-model="keyword" />
        <div @click="loadData"><img src="@/assets/search.png" /></div>
      </div>
      <div class="document-content">
        <div class="document-type">
          <div
            v-for="n in getTag"
            :class="tag == n.id ? 'active' : ''"
            @click="
              tag = n.id;
              loadData();
            "
            :key="n.id"
          >
            {{ n.name }}
          </div>
        </div>
        <div class="document-list">
          <router-link v-for="n in data" :key="n.id" :to="'/detail/' + n.id">
            <p>{{ n.title }}</p>
            <span>{{ $dateFormat(n.updatetime) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from "@/api";
export default {
  name: "document",
  components: {},
  data() {
    return {
      map: null,
      list: [],
      statusList: [],
      images: [],
      data: [],
      tag: 0,
      keyword: "",
    };
  },
  computed: {
    getTag() {
      const data = this.$store.state.navAndTag.find(
        (n) => n.id == this.$route.params.id
      );
      return data ? [{ name: "全部", id: 0 }, ...data.tag] : [];
    },
  },
  async created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const { data } = await getArticleList({
        keyword: this.keyword,
        category_id: this.$route.params.id,
        tag_id: this.tag,
      });
      this.data = data.data;
    },
  },
  watch: {
    $route: {
      handler() {
        this.keyword = "";
        this.loadData();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: absolute;
  min-height: 160px;
}
.document {
  position: relative;
  padding: 50px 0 20px;
  min-height: calc(100vh - 170px);
  box-sizing: border-box;
  .search {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
    height: 60px;
    line-height: 60px;
    background: #0055af;
    border: 1px solid #0055af;
    input {
      background: #fff;
      padding: 0 20px;
      flex: 1;
      outline: none;
      font-size: 18px;
    }
    > div {
      width: 80px;
      height: 60px;
      img {
        width: 40px;
        margin: 10px 20px;
      }
    }
  }
  .document-content {
    padding-top: 100px;
    display: flex;
    .document-type {
      width: 244px;
      > div {
        padding: 0 20px 20px 30px;
        margin-bottom: 10px;
        font-size: 18px;
      }

      > div.active {
        font-weight: bold;
        position: relative;
      }
      > div.active::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 21px;
        background: #0055af;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
    .document-list {
      padding-left: 20px;
      border-left: 1px solid #d8d8d8;
      flex: 1;
      a {
        color: #000000;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        p {
          overflow: hidden;
          flex: 1;
          text-overflow: ellipsis;
          margin-right: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        span {
          color: #818181;
        }
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .document {
    .search {
      height: 40px;
      line-height: 40px;
      input {
        padding: 0 10px;
        font-size: 16px;
      }
      > div {
        width: 50px;
        height: 40px;
        img {
          width: 30px;
          margin: 4px 10px;
        }
      }
    }
    .document-content {
      padding: 80px 15px 0;
      .document-type {
        width: 60px;
      }
    }
  }
}
</style>
