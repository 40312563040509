import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
  },
  state: {
    navAndTag: [],
  },
  mutations: {
    SET_TAG: (state, data) => {
      state.navAndTag = data
    }
  },
  actions: {

  },
  modules: {

  }
})
