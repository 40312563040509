// 服务器上传文件地址
export const IMG_UP_URL = ""
// 服务器静态文件地址
export const IMG_URL = "/uploads/miniprogram/"

import router from '@/router'
import { setTokenAUTH } from "@/api/auth";
export const logOut = () => {
    setTokenAUTH('');
    router.push('/login');
}

//百度坐标转火星坐标：bd09II=>gcj02
var x_pi = 3.14159265358979324 * 3000.0 / 180.0;
export function baiduTomars(baidu_point) {
    var mars_point = { lat: 0, lng: 0, };
    var x = baidu_point.lng - 0.0065;
    var y = baidu_point.lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    mars_point.lat = z * Math.sin(theta);
    mars_point.lng = z * Math.cos(theta);
    return mars_point;
}
//火星坐标系GCJ02转地球坐标系WGS84
var PI = 3.14159265358979324;
export function transformGCJ2WGS(gc_point) {
    var gcjLat = gc_point.lat;
    var gcjLon = gc_point.lng;
    let d = delta(gcjLat, gcjLon)
    return {
        'lat': gcjLat - d.lat,
        'lng': gcjLon - d.lng
    }
}
function delta(lat, lng) {
    let a = 6378245.0
    let ee = 0.00669342162296594323
    let dLat = transformLat(lng - 105.0, lat - 35.0)
    let dLon = transformLon(lng - 105.0, lat - 35.0)
    let radLat = lat / 180.0 * PI
    let magic = Math.sin(radLat)
    magic = 1 - ee * magic * magic
    let sqrtMagic = Math.sqrt(magic)
    dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * PI)
    dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * PI)
    return {
        'lat': dLat,
        'lng': dLon
    }
}
function transformLat(x, y) {
    let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0
    ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0
    return ret
}
function transformLon(x, y) {
    let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0
    ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0
    return ret
}
