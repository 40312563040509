<template>
  <div id="app" ref="app">
    <keep-alive>
      <router-view v-if="getKeepAlive"></router-view>
      <!--这里是会被缓存的组件-->
    </keep-alive>
    <router-view v-if="!getKeepAlive"></router-view>
    <!--这里是不会被缓存的组件-->
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      scrollTop: 0,
    };
  },
  computed: {
    getKeepAlive() {
      const { meta } = this.$route;
      // console.log(meta);
      if (meta) {
        if (meta.keepAlive) {
          return true;
        }
      }
      return false;
    },
  },
  async created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="less" >
html {
  overflow-x: hidden;
  background-color: @background-color;
}
body,
p {
  margin: 0;
}
a {
  text-decoration: none;
}
img {
  object-fit: cover;
  width: 100%;
}
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.flex {
  display: flex;
  align-items: center;
}
.time3,
.time3 * {
  transition: all ease-out 0.3s 0s;
  -webkit-transition: all ease-out 0.3s 0s;
  -moz-transition: all ease-out 0.3s 0s;
  -o-transition: all ease-out 0.3s 0s;
}
.min-width {
  width: 1280px;
  margin: auto;
}
@media screen and (max-width: 1300px) {
  .min-width {
    width: 1080px;
    margin: auto;
  }
}
@media screen and (max-width: 1100px) {
  .min-width {
    width: 880px;
    margin: auto;
  }
}
@media screen and (max-width: 900px) {
  .min-width {
    width: 100%;
    padding:0 15px;
    box-sizing: border-box;
    margin: auto;
  }
}
</style>
