<template>
  <div>
    <video ref="videoPlayer" class="video-js" v-if="getType" :style="styleVideo"></video>
    <img :src="options.src" style="height: 100%" alt="" v-else-if="showImg" />
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    styleVideo: {
      type: Object,
    },
    showImg: {
      type: Boolean,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  computed: {
    getType() {
      const { src } = this.options;
      return this.$fileSuffixTypeUtil(src) == "video";
    },
  },
  async mounted() {
    const { src } = this.options;
    if (src) {
      await this.$nextTick();
      if (this.getType) {
        this.player = videojs(
          this.$refs.videoPlayer,
          {
            ...this.options,
            sources: [
              {
                src: src,
              },
            ],
          },
          function onPlayerReady() {
            console.log("onPlayerReady", this);
          }
        );
      }
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    async options() {},
  },
};
</script>
<style>
.video-js {
  width: 100%;
  height: 100%;  
}
</style>