<template>
  <div>
    <img src="@/assets/bg.jpg" class="bg" alt="" />
    <div class="home min-width">
      <div class="home-top">
        <h2>瑞安陈岙村：打通“两山”转化通道，逆袭走向共同富裕</h2>
        <p></p>
        <div class="wrap-2 flex">
          <div class="">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="i in banner" :key="i.id">
                <img :src="i.image" width="100%" />
                <p>{{ i.title }}</p>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="news" style="overflow:auto">
            <p>陈岙新闻</p>
            <router-link
              v-for="n in article.news"
              :key="n.id"
              :to="'/detail/' + n.id"
            >
              <p>{{ n.title }}</p>
              <span>{{ $dateFormat(n.updatetime) }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="home-center wrap-2 flex">
        <div class="news">
          <p>乡村文明<img src="@/assets/shan1.png" /></p>
          <template v-for="(n, index) in article.civilization">
            <router-link
              :to="'/detail/' + n.id + '?type=civilization'"
              class="news-top"
              v-if="index == 0"
            >
              <img :src="n.image" alt="" />
              <div>
                <p>{{ n.title }}</p>
                <p>{{ n.subtitle }}</p>
                <p>{{ $dateFormat(n.updatetime) }}</p>
              </div>
            </router-link>
            <router-link :to="'/detail/' + n.id + '?type=civilization'" v-else
              ><p>{{ n.title }}</p>
              <span>{{ $dateFormat(n.updatetime) }}</span></router-link
            >
          </template>
        </div>
        <div class="news">
          <p>阳光村务<img src="@/assets/shan1.png" /></p>
          <template v-for="(n, index) in article.sunlight">
            <router-link
              :to="'/detail/' + n.id + '?type=sunlight'"
              class="news-top"
              v-if="index == 0"
            >
              <img :src="n.image" alt="" />
              <div>
                <p>{{ n.title }}</p>
                <p>{{ n.subtitle }}</p>
                <p>{{ $dateFormat(n.updatetime) }}</p>
              </div>
            </router-link>
            <router-link :to="'/detail/' + n.id + '?type=sunlight'" v-else
              ><p>{{ n.title }}</p>
              <span>{{ $dateFormat(n.updatetime) }}</span></router-link
            >
          </template>
        </div>
      </div>
      <div class="home-end">
        <p>陈岙风采<img src="@/assets/shan2.png" /></p>
        <router-link
          v-for="n in article.style"
          :to="`/detail/${n.id}?type=style`"
          :key="n.id"
        >
          <!-- <img :src="n.abb_file" alt="" /> -->
          <video-player
            showImg
            :options="{
              autoplay: false,
              controls: false,
              src: n.abb_file,
            }"
          />
          <p>{{ n.title }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getBanner, getArticle } from "@/api";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    VideoPlayer,
  },
  data() {
    return {
      swiperOptions: {
        pagination: { el: ".swiper-pagination" },
        paginationClickable: true,
      },
      banner: [],
      article: {},
    };
  },
  computed: {},
  async created() {
    const { data: banner } = await getBanner();
    this.banner = banner;
    const { data: article } = await getArticle();
    this.article = article;
  },
  methods: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.bg {
  height: 730px;
  position: absolute;
}
.home {
  * {
    transition: all ease-out 0.3s 0s;
    -webkit-transition: all ease-out 0.3s 0s;
    -moz-transition: all ease-out 0.3s 0s;
    -o-transition: all ease-out 0.3s 0s;
  }
  padding-top: 475px;
  position: relative;

  .home-top {
    padding: 40px 32px 30px;
    // height: 568px;
    background: #ffffff;
    > h2 {
      text-align: center;
      font-size: 38px;
      margin: 0 0 18px;
    }
    > p {
      text-align: center;
      font-size: 16px;
      color: #818181;
    }
    .swiper-slide {
      img {
        height: 360px;
      }
      p {
        text-align: left;
        width: 100%;
        text-indent: 2em;
        position: absolute;
        bottom: 0;
        line-height: 56px;
        background: #00000088;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .wrap-2 {
    margin: 46px 0 20px;
    justify-content: space-between;
    > div {
      width: 48%;
      height: 360px;
      overflow: hidden;
    }
    .news {
      .news-top {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        img {
          width: 240px;
          height: 140px;
        }
        div {
          overflow: hidden;
          flex: 1;
          margin-left: 14px;
          p:first-child {
            font-size: 19px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          p {
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            white-space: inherit;
          }
          p:last-child {
            color: #818181;
            font-size: 14px;
          }
        }
      }
      > p {
        font-size: 23px;
        color: #0055af;
        padding-bottom: 16px;
        font-weight: 600;
        border-bottom: 1px solid #d8d8d8;
        position: relative;
        img {
          width: 100px;
          position: absolute;
          right: 0;
        }
      }
      > a {
        color: #000000;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        p {
          overflow: hidden;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 10px;
        }
        span {
          color: #818181;
        }
      }
    }
  }
  .home-center {
    .news {
      p {
        color: #000000;
      }
    }
  }
  .home-end {
    > p {
      font-size: 23px;
      text-align: center;
      line-height: 40px;
      margin: 20px 0;
      position: relative;

      img {
        width: 220px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }
    > a {
      width: 23.5%;
      display: inline-block;
      margin-right: 2%;
      margin-bottom: 20px;
      > div {
        width: 100%;
        height: 180px;
      }
      p {
        margin-top: 12px;
        height: 44px;
        line-height: 22px;
        overflow: hidden;
        color: #000000;
        text-overflow: ellipsis;
        margin-right: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > a:nth-child(4n + 1) {
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 900px) {
  .home {
    .wrap-2 {
      display: block;
      > div {
        width: 100%;
      }
    }
    .news {
      margin-top: 10px;
    }
    .home-center {
      padding: 0 32px;
    }
    .home-end {
      padding: 0 32px;
      > a {
        width: 32%;
        margin-right: 2%;
      }
      > a:nth-child(4n + 1) {
        margin-right: 2%;
      }
      > a:nth-child(3n + 1) {
        margin-right: 0%;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .home {
    .home-center {
      padding: 0 20px;
    }
    .home-top {
      padding: 20px 15px 15px;
      > h2 {
        font-size: 24px;
        margin: 0 0 18px;
      }

      .swiper-slide {
        img {
          height: 240px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    .home-end {
      padding: 0 20px;
      > a {
        width: 48%;
        margin-right: 4%;
      }
      > a:nth-child(4n + 1) {
        margin-right: 4%;
      }
      > a:nth-child(3n + 1) {
        margin-right: 4%;
      }
      > a:nth-child(2n + 1) {
        margin-right: 0%;
      }
    }
    .wrap-2 {
      margin: 16px 0 10px;
      justify-content: space-between;
      > div {
        height: 240px;
      }
      .news {
        .news-top {
          img {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
<style lang="less" >
.swiper-container-horizontal > .swiper-pagination-bullets {
  text-align: right;
  bottom: 20px !important;
  box-sizing: border-box;
  padding-right: 10px;
  .swiper-pagination-bullet {
    background: #ffffff;
  }
}
</style>
