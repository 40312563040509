import { IMG_URL, IMG_UP_URL } from "@/unit";
import { fileUpload, partySite, getArticleList } from "@/api";
import { Promise } from "core-js";
import router from '@/router'
export default {
    install(Vue, options) {

        // 上传图片
        Vue.prototype.$afterRead = function ({ file }, key) {
            const list = this[key];
            let param = new FormData(); // 创建form对象
            param.append("file", file); // 通过append向form对象添加数据
            fileUpload(param).then((res) => {
                this.$set(this[key][list.length - 1], 'url', res.data.fullurl)
            })

        }
        // 获取图片地址
        Vue.prototype.$getImgUrl = function (url) {
            console.log(url)
            url = url || 'default_avatar'
            // console.log(url)
            if (url) {
                if (url.includes("https")) {
                    return url
                }
                if (url.includes(".")) {
                    return IMG_UP_URL + url;
                }
                return IMG_URL + url + ".png";
            }
            return ""
        }
        // 时间格式化
        Vue.prototype.$dateFormat = function (d, fmt = "YYYY-mm-dd") {
            const date = new Date(d < 16602853440 ? d * 1000 : d)
            let ret;
            const opt = {
                "Y+": date.getFullYear().toString(),        // 年
                "m+": (date.getMonth() + 1).toString(),     // 月
                "d+": date.getDate().toString(),            // 日
                "H+": date.getHours().toString(),           // 时
                "M+": date.getMinutes().toString(),         // 分
                "S+": date.getSeconds().toString()          // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            };
            for (let k in opt) {
                ret = new RegExp("(" + k + ")").exec(fmt);
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
                };
            };
            return fmt;
        }
        // 获取经纬度和距离
        Vue.prototype.$getPosition = async ({ BMap, map, data }) => {

            // 获取当前位置
            const geolocation = new BMap.Geolocation();
            const getGeolocation = new Promise((res) => {
                geolocation.getCurrentPosition(res);
            })
            const { latitude, longitude } = await getGeolocation;

            // 计算距离
            const getTransit = (a, b) => {
                return new Promise(res => {
                    const transit = new BMap.DrivingRoute(map, {
                        renderOptions: {},
                        onSearchComplete: (results) => {
                            if (transit.getStatus() != 0) {
                                return;
                            }
                            res(results.getPlan(0).getDistance(true))
                        },
                    });
                    var start = new BMap.Point(a.lng, a.lat);
                    var end = new BMap.Point(b.lng, b.lat);
                    transit.search(start, end);
                })
            }

            // 坐标转化
            const convertor = new BMap.Convertor();
            const getTranslate = (point) => {
                return new Promise((res) => {
                    const pointArr = [point].map((i) => new BMap.Point(i.lng, i.lat));
                    convertor.translate(pointArr, 3, 5, res)
                })
            }
            // const { points } = await getTranslate

            const points = []
            let res = await Promise.all(data.map(async (i, index) => {
                const { points: [point] } = await getTranslate(i)
                const transit = await getTransit({ lng: longitude, lat: latitude }, point)
                points.push({ ...point, transit })
                i.transit = transit

            }))
            // console.log(data)
            return { points, list: data }
        }
        // 跳转地图
        Vue.prototype.$to = ({ lat, lng, name }) => {
            window.location.href = `https://apis.map.qq.com/uri/v1/routeplan?type=drive&fromcoord&to=${name}&tocoord=${lat},${lng}&policy=1&referer=7O6BZ-FPPWD-AH64E-HRVUJ-QNUGF-E3BJ4`;
        }
        // 图片处理
        Vue.prototype.$getFileList = (files = '') => {
            return files.split(',').map((i) => {
                return { url: i };
            })
        }
        // 跳转详情

        Vue.prototype.$fileSuffixTypeUtil = (fileName) => {
            // 后缀获取
            var suffix = ''
            // 获取类型结果
            var result = ''
            try {
                var flieArr = fileName.split('.')
                suffix = flieArr[flieArr.length - 1]
            } catch (err) {
                suffix = ''
            }
            // fileName无后缀返回 false
            if (!suffix) {
                result = false
                return result
            }
            // 图片格式
            var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
            // 进行图片匹配
            result = imglist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'image'
                return result
            }
            // 匹配txt
            var txtlist = ['txt']
            result = txtlist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'txt'
                return result
            }
            // 匹配 excel
            var excelist = ['xls', 'xlsx']
            result = excelist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'excel'
                return result
            }
            // 匹配 word
            var wordlist = ['doc', 'docx']
            result = wordlist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'word'
                return result
            }
            // 匹配 pdf
            var pdflist = ['pdf']
            result = pdflist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'pdf'
                return result
            }
            // 匹配 ppt
            var pptlist = ['ppt']
            result = pptlist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'ppt'
                return result
            }
            // 匹配 视频
            var videolist = ['mp4', 'm2v', 'mkv']
            result = videolist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'video'
                return result
            }
            // 匹配 音频
            var radiolist = ['mp3', 'wav', 'wmv']
            result = radiolist.some(function (item) {
                return item == suffix
            })
            if (result) {
                result = 'radio'
                return result
            }
            // 其他 文件类型
            result = 'other'
            return result
        }



    }
}