<template>
  <div>
    <div class="top">
      <div class="flex min-width">
        <p>陈岙村，欢迎您的到来！</p>
        <p>{{ $dateFormat(new Date(), "YYYY年mm月dd HH:MM") }}</p>
      </div>
    </div>
    <div class="nav">
      <div class="flex min-width">
        <img src="@/assets/logo.png" class="logo" alt="" />
        <div></div>
        <router-link to="/home">首页</router-link>
        <router-link
          v-for="n in navAndTag"
          :key="n.id"
          :to="'/document/' + n.id"
          >{{ n.name }}</router-link
        >
      </div>
    </div>
    <div class="nav-h5">
      <div class="flex min-width">
        <router-link to="/home">首页</router-link>
        <router-link
          v-for="n in navAndTag"
          :key="n.id"
          :to="'/document/' + n.id"
          >{{ n.name }}</router-link
        >
      </div>
    </div>
    <router-view></router-view>
    <div class="end">
      <p>Copyright © 2023 瑞安市塘下镇陈岙村股份经济合作社 版权所有</p>
      <div style="width: 500px; margin: 0 auto">
        <a
          target="_blank"
          href=""
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="@/assets/icon.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            浙公网安备 33038102332281号 <a href="https://beian.miit.gov.cn/">&nbsp;&nbsp;浙ICP备2023011918号-1</a>
          </p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getNavAndTag } from "@/api";
export default {
  name: "index",
  components: {},
  data() {
    return {
      list: [],
      images: [],
      text: "",
      navAndTag: [],
    };
  },
  computed: {},
  async created() {
    const { data: navAndTag } = await getNavAndTag();
    this.navAndTag = navAndTag;
    this.$store.commit("SET_TAG", navAndTag);
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.top {
  line-height: 30px;
  height: 30px;
  background: #333b44;
  text-align: center;

  color: #ffffff;
  font-size: 12px;
  .flex {
    justify-content: space-between;
  }
}
.nav {
  width: 100%;
  height: 80px;
  background-image: linear-gradient(
    180deg,
    rgba(26, 34, 43, 0.38) 0%,
    rgba(255, 255, 255, 0) 62%
  );

  .flex {
    justify-content: end;
    .logo {
      width: 200px;
    }
    > div {
      flex: 1;
    }
    a {
      line-height: 80px;
      opacity: 0.7;
      font-size: 18px;
      color: #000000;
      margin-left: 60px;
    }

    > a.router-link-active,
    > a:hover {
      opacity: 1;
      font-weight: 600;
      position: relative;
    }
    > a.router-link-active::after {
      position: absolute;
      content: "";
      display: block;
      left: 50%;
      top: 100%;
      width: 26px;
      height: 6px;
      background: #0055af;
      transform: translate(-50%, -100%);
    }
  }
}
.nav-h5 {
  display: none;
}
.end {
  line-height: 28px;
  height: 60px;
  background: #333b44;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  img {
    width: 20px;
  }
}
@media screen and (max-width: 750px) {
  .nav {
    display: none;
  }
  .nav-h5 {
    display: block;
    height: 56px;
    a {
      width: 25%;
      text-align: center;
      line-height: 54px;
      color: #000000;
    }
    a.router-link-active {
      font-weight: bold;
    }
  }
  .end {
    height: 60px;
    background: #333b44;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
  }
}
</style>
